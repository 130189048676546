/*  imported from index.less  */

#meepshop { .meepshop-iframe__index__root {
  width: 100%;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a,
  a:hover,
  a:active,
  a:focus,
  a:visited,
  .meepshop-iframe__index__ant-form {
    color: inherit;
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";